import { useState, useEffect } from "react";
import { Switch } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import { getYandexLink } from "./services/api_requests";

import "react-toastify/dist/ReactToastify.css";

import "./App.css";

import logo from "./assets/pre_logo_esp.png";
import Book from "./pages/main_page/components/icons/Book";

import PrivateRoute from "./route_components/PrivateRoute";
import PublicRoute from "./route_components/PublicRoute";
import MainPage from "./pages/main_page/MainPage";
import AuthPage from "./pages/auth_page/AuthPage";
import CustomContentContainer from "./special_components/CustomContentContainer";

function App() {
  const location = useLocation();
  const history = useHistory();

  const [checking, setChecking] = useState(true);
  const [auth, setAuth] = useState(false);
  const [urlText, setUrlText] = useState("");
  const [urlId, setUrlId] = useState("");
  const [urlCheckable, setUrlCheckable] = useState("1");

  useEffect(() => {
    const hasToken = Boolean(localStorage.token);

    if (hasToken) {
      setAuth(true);
    }

    setChecking(false);
  }, [location.pathname]);

  useEffect(() => {
    const string = decodeURI(location.search);

    if (string.trim()) {
      const arr = string.split("/?");
      console.log(arr);

      const refactoredArr = arr.map((el, i) => {
        if (i === 0) {
          return el.slice(1);
        } else {
          return el;
        }
      });

      refactoredArr.forEach((el) => {
        if (el.includes("text")) {
          setUrlText(el.slice(5).split("_").join(" "));
        } else if (el.includes("id")) {
          setUrlId(el.slice(3));
        } else {
          setUrlCheckable(el.slice(-1));
        }
      });
    }
  }, [location.search]);

  const exit = () => {
    localStorage.removeItem("token");
    setAuth(false);
    history.push("/auth");
  };

  const download = async () => {
    try {
      const response = await getYandexLink();
      const data = await response.json();
      let tempLink = document.createElement("a");
      tempLink.href = data.href;
      tempLink.target = "_blank";
      tempLink.rel = "noopener noreferrer";
      tempLink.click();
    } catch (err) {
      const message = `Error: ${err.message} Intente descargar este archivo más tarde.`;

      toast.error(message, {
        position: "top-center",
      });
    }
  };

  return (
    <>
      {!checking && (
        <>
          <CustomContentContainer type="header_content">
            <Container fluid id="header-block">
              <div className="logo__wrapper">
                <img src={logo} alt="logo" width="300" height="50" />
              </div>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id={`tooltip-manual`}>
                    Descargar manual del usuario
                  </Tooltip>
                }
              >
                <Button
                  className={"manual-btn"}
                  variant="outline-secondary"
                  onClick={download}
                >
                  <Book />
                </Button>
              </OverlayTrigger>
              {auth && (
                <Button
                  className={"logout-btn"}
                  variant="outline-secondary"
                  onClick={exit}
                >
                  Cerrar sesión
                </Button>
              )}
            </Container>
          </CustomContentContainer>
          <Switch>
            <PrivateRoute path="/" exact auth={auth} redirectTo="/auth">
              <MainPage
                urlText={urlText}
                urlId={urlId}
                urlCheckable={urlCheckable}
              />
            </PrivateRoute>
            <PublicRoute path="/auth" exact auth={auth} redirectTo="/">
              <AuthPage handleAuth={setAuth} />
            </PublicRoute>
          </Switch>
        </>
      )}
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
