import { useState, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { getExelFile } from "../../../../services/api_requests";

import "./UsefulBlock.css";

import Copy from "../icons/copy";
import Email from "../icons/Email";
import Exel from "../icons/Exel";

export default function UsefulBlock({ data, saveMessageText }) {
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const [resultText, setResultText] = useState("");
  const [resultCleanText, setResultCleanText] = useState("");

  useEffect(() => {
    const diagnosesList = [];
    const approvedList = [];
    const partlyApproved = [];
    const declinedList = [];

    let text;
    let cleanText;

    const count = "en cantidad";
    const reason = "razón";
    const introDiagnoses = "Según diagnósticos en la solicitud";
    const introApproved = "Servicios aprobados";
    const introPartiallyApproved = "Servicios aprobados parcialmente ";
    const introNotApproved = "Servicios no aprobados";

    [...data.diagnosisList].forEach((diagnosis) => {
      diagnosesList.push(` ${diagnosis.MKB_CODE}`);
    });
    [...data.servicesTable].forEach((service) => {
      const targetCount = data.countsBefore?.find((el) => el[service._id]);
      let equality;
      let delta;

      if (targetCount) {
        equality = parseInt(service.serviceCount) === targetCount[service._id];
        delta = targetCount[service._id] - parseInt(service.serviceCount);
      }

      const approvementChanged = service.corrected === "Да";

      const countCheck = parseInt(service.serviceCount) > 1;

      const toothCheck = service.toothNum !== "" && service.toothNum !== "0";

      if (service.approveResult && equality) {
        approvedList.push(
          ` ${service.serviceName}${toothCheck ? ` ${service.toothNum}` : ""}${
            countCheck ? ` ${count} ${service.serviceCount}` : ""
          }`
        );
      }
      if (!service.approveResult && equality) {
        declinedList.push(
          ` ${service.serviceName}, ${reason}: ${service.declineReason}`
        );
      }
      if (service.approveResult && !equality && approvementChanged) {
        partlyApproved.push(
          ` ${service.serviceName}${toothCheck ? ` ${service.toothNum}` : ""}${
            countCheck ? ` ${count} ${service.serviceCount}` : ""
          }`
        );
      }
      if (!service.approveResult && !equality && !approvementChanged) {
        approvedList.push(
          ` ${service.serviceName}${toothCheck ? ` ${service.toothNum}` : ""}${
            countCheck ? ` ${count} ${delta}` : ""
          }`
        );
        declinedList.push(
          ` ${service.serviceName}${toothCheck ? ` ${service.toothNum}` : ""}${
            countCheck ? ` ${count} ${service.serviceCount}` : ""
          }, ${reason}: ${service.declineReason}`
        );
      }
    });

    text = `${
      diagnosesList.length > 0 ? `${introDiagnoses}:${diagnosesList}.%0D` : ""
    }${approvedList.length > 0 ? `${introApproved}:${approvedList}.%0D` : ``}${
      partlyApproved.length > 0
        ? `${introPartiallyApproved}:${partlyApproved}.%0D`
        : ``
    }${declinedList.length > 0 ? `${introNotApproved}:${declinedList}.` : ``}`;

    cleanText = `${
      diagnosesList.length > 0 ? `${introDiagnoses}:${diagnosesList}.` : ""
    }${" "}${
      approvedList.length > 0 ? `${introApproved}:${approvedList}.${" "}` : ``
    }${
      partlyApproved.length > 0
        ? `${introPartiallyApproved}:${partlyApproved}.${" "}`
        : ``
    }${declinedList.length > 0 ? `${introNotApproved}:${declinedList}.` : ``}`;

    setResultText(text);
    setResultCleanText(cleanText);
  }, [data]);

  const onCopyClick = () => {
    saveMessageText(resultCleanText);
    navigator.clipboard.writeText(resultCleanText);
  };

  const onEmailClick = () => {
    saveMessageText(resultCleanText);

    window.location.href = "mailto:?subject=Approvement&body=" + resultText;
  };

  const onExcelClick = async () => {
    saveMessageText(resultCleanText);
    await onExcel();
  };

  const onExcel = async (e) => {
    try {
      setIsLoadingLocal(true);

      const response = await getExelFile(data);

      const file = await response.blob();
      _saveFile(file);
    } catch (err) {
      const message = `Error: ${err.message} Intente descargar este archivo más tarde.`;

      toast.error(message, {
        position: "top-center",
      });
    } finally {
      setIsLoadingLocal(false);
    }
  };

  const _saveFile = (file) => {
    let url = window.URL.createObjectURL(file);
    let tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", "Результат согласования.xlsx");
    tempLink.click();
  };

  return (
    <div className="UsefulBlock">
      <OverlayTrigger
        placement="top-start"
        overlay={
          <Tooltip id={`tooltip-excel`}>Descargar archivo Excel</Tooltip>
        }
      >
        <Button
          className="useful-btn"
          variant="outline-secondary"
          disabled={!data.requestIsAnalized}
          onClick={onExcelClick}
        >
          {isLoadingLocal ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <Exel />
          )}
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top-start"
        overlay={
          <Tooltip id={`tooltip-email`}>Enviar correo electronico</Tooltip>
        }
      >
        <Button
          className="useful-btn"
          variant="outline-secondary"
          disabled={!data.requestIsAnalized}
          onClick={onEmailClick}
        >
          <Email />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top-start"
        overlay={<Tooltip id={`tooltip-copy`}>Copiar al portepapeles</Tooltip>}
      >
        <Button
          className="useful-btn"
          variant="outline-secondary"
          disabled={!data.requestIsAnalized}
          onClick={onCopyClick}
        >
          <Copy />
        </Button>
      </OverlayTrigger>
    </div>
  );
}
