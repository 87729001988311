import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAuthenticated } from "../../services/api_requests";

import "./AuthPage.css";

const INITIAL_STATE = {
  login: "",
  password: "",
};

export default function AuthPage({ handleAuth }) {
  const history = useHistory();

  const [login, setLogin] = useState(INITIAL_STATE.login);
  const [password, setPassword] = useState(INITIAL_STATE.password);

  const inputChangeHandler = (e) => {
    const { name, value } = e.currentTarget;

    switch (name) {
      case "login":
        setLogin(value);
        break;
      case "password":
        setPassword(value);
        break;

      default:
        break;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const auth = await _auth();

      setLogin(INITIAL_STATE.login);
      setPassword(INITIAL_STATE.password);

      if (auth) {
        handleAuth(true);
        history.push("/");
      }
    } catch (err) {
      toast.error(`Error: ${err.message} Vuelva a intentarlo más tarde.`, {
        position: "top-center",
      });
    }
  };

  const _auth = async () => {
    let response = await getAuthenticated(login, password);

    let data = await response.json();

    if (data.error) {
      toast.error("El usuario no existe!", {
        position: "top-center",
      });

      return false;
    }

    localStorage.setItem("token", data.token);
    localStorage.setItem("login", data.login);
    return true;
  };

  return (
    <>
      <Container id={"AuthPage"} fluid>
        <h1 className="auth-heading">
          Por favor, inicie sesión en la aplicación
        </h1>
        <Form className="auth-form" autoComplete="off" onSubmit={submitHandler}>
          <Form.FloatingLabel
            className="mb-4"
            controlId="formBasicEmail"
            label="Usuario"
          >
            <Form.Control
              type="text"
              name="login"
              value={login}
              required
              onChange={inputChangeHandler}
              placeholder="Usuario"
            />
          </Form.FloatingLabel>

          <Form.FloatingLabel
            className="mb-4"
            controlId="formBasicPassword"
            label="Contraseña"
          >
            <Form.Control
              type="password"
              name="password"
              value={password}
              required
              onChange={inputChangeHandler}
              placeholder="Contraseña"
            />
          </Form.FloatingLabel>

          <Button variant="primary" type="submit">
            Iniciar sesión
          </Button>
        </Form>
      </Container>
    </>
  );
}
