import React, { Component } from "react";

import {
  Container,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { toast } from "react-toastify";

import "./MainPage.css";
import diagnosisOptionsListEn from "../../dictionaries/mkb_en.json";

import {
  getRequestClassified,
  getDataAnalyzed,
  getServicesOptions,
  getDeclinesOptions,
  getApprovedTask,
  getOrganizationChecked,
} from "../../services/api_requests";

import CustomContentComponent from "../../special_components/CustomContentContainer";
import DiagnosisList from "./components/diagnosis_list/diagnosis_list";
import ServicesTable from "./components/services_table/services_table";
import LoaderWrapper from "./components/loader_wrapper/loader_wrapper";
import BlockHeader from "./components/block_header/BlockHeader";
import UsefulBlock from "./components/useful_block/UsefulBlock";
import Done from "./components/icons/Done";
import Bed from "./components/icons/Bed";
import OverLayLoader from "./components/OverlayLoader/OverLayLoader";

class MainPage extends Component {
  state = {
    text: "",
    diagnosisList: [],
    servicesTable: [],
    declineReasonList: [],
    declineReasonListEn: [],
    requestIsClassified: false,
    requestIsAnalized: false,
    requestIsApproved: false,
    isLoading: false,
    isLoadingLocal: false,
    visibleTables: false,
    servicesOptions: [],
    servicesOptionsEn: [],
    addedServices: [],
    removedServices: [],
    addedDiagnosis: [],
    removedDiagnosis: [],
    sessionId: "",
    organization: "",
    login: "",
    messageText: "",
    countsBefore: [],
    caseType: "usual",
    checkable: "1",
    sex: "M",
    age: 0,
    lpuCode: "1",
    policyNumber: "1",
  };

  componentDidMount = async () => {
    try {
      this.setState({
        isLoading: true,
        visibleTables: false,
        login: localStorage.getItem("login"),
        text: this.props.urlText,
        policyNumber: this.props.urlId,
        checkable: this.props.urlCheckable,
      });

      await this.fillOrganization();

      Promise.all([
        getServicesOptions("en", { signal: this.controller.signal }),
        getDeclinesOptions("en", { signal: this.controller.signal }),
      ])
        .then((responses) => Promise.all(responses.map((r) => r.json())))
        .then((fetchedData) => {
          this.setState({
            servicesOptionsEn: fetchedData[0].data,
            declineReasonListEn: fetchedData[1].data,
          });
        });
      // This if below is for
      if (this.props.urlText) {
        this.onClassified();
      }

      this.setState({
        isLoading: false,
      });
    } catch (err) {
      if (err.name === "AbortError") return;
      const message = `Error: ${err.message} Vuelva a intentarlo más tarde.`;
      toast.error(message, {
        position: "top-center",
      });

      this.setState({
        isLoading: false,
      });
    }
  };

  addDiagnose = () => {
    let lastIndex = this.state.diagnosisList.length - 1;
    let newId;

    lastIndex < 0
      ? (newId = 0)
      : (newId = this.state.diagnosisList[lastIndex]._id + 1);
    let newDiagnose = {
      _id: newId,
      diagnosisName: "",
      exacerbation: false,
      normativeAct: "",
    };

    this.setState((prevState) => ({
      diagnosisList: [...prevState.diagnosisList, newDiagnose],
      addedDiagnosis: [...prevState.addedDiagnosis, newDiagnose],
    }));
  };

  onChangeDiagnosis = (index, e) => {
    let diagnosisList = this.state.diagnosisList;
    let diagnose = diagnosisList[index];

    diagnose.diagnosisName = e.value;
    const diagnosisData = this._getDataByDiagnosisName(diagnose.diagnosisName);
    diagnose.normativeAct = diagnosisData.normativeAct;
    diagnose.normativeActLink = diagnosisData.normativeActLink;
    diagnose.normativeActFullText = diagnosisData.normativeActFullText;
    diagnose.MKB_CODE = diagnosisData.MKB_CODE;

    diagnosisList[index] = diagnose;

    this.setState({
      diagnosisList,
    });
  };

  onRemoveDiagnosis = (index) => {
    let diagnosisList = this.state.diagnosisList;

    let removedDiagnosis = this.state.removedDiagnosis;
    removedDiagnosis.push(diagnosisList[index]);

    diagnosisList.splice(index, 1);

    this.setState({
      diagnosisList,
      removedDiagnosis,
    });
  };

  onChangeExacerbation = (index) => {
    let renewedDiagnosisList = this.state.diagnosisList.map((diagnose, i) => {
      return i === index
        ? { ...diagnose, exacerbation: !diagnose.exacerbation }
        : diagnose;
    });
    this.setState({
      diagnosisList: renewedDiagnosisList,
    });
  };

  onRemoveService = (index) => {
    let servicesTable = this.state.servicesTable;

    let removedServices = this.state.removedServices;
    removedServices.push(servicesTable[index]);

    servicesTable.splice(index, 1);

    this.setState({
      servicesTable,
    });
  };

  onApproveService = (index) => {
    const initialReason = "No seleccionado";
    let servicesTable = this.state.servicesTable;
    servicesTable[index].approveResult = !servicesTable[index].approveResult;

    servicesTable[index].approveResult
      ? (servicesTable[index].declineReason = null)
      : (servicesTable[index].declineReason = initialReason);
    servicesTable[index].corrected =
      servicesTable[index].approveResult !==
      servicesTable[index].initialApproved
        ? "Да"
        : "Нет";

    this.setState({
      servicesTable,
    });
  };

  _changeService = (columnName, index, e) => {
    let servicesTable = this.state.servicesTable;
    let service = servicesTable[index];

    let newValue = e.value;

    service[columnName] = newValue;
    servicesTable[index] = service;

    this.setState({
      servicesTable,
    });
  };

  onChangeServiceName = (columnName, index, e) => {
    const prevTable = this.state.servicesTable;
    let servicePrev = prevTable[index];
    if (servicePrev[columnName] !== "") {
      const criterio = this.state.servicesOptions.find(
        (item) => item.value === e.value
      );
      if (!criterio) {
        const message =
          "¡Atención! Para crear un servicio personalizado, añada uno nuevo vacío y edítelo.";
        toast.warning(message, {
          position: "top-center",
          autoClose: 5000,
        });
        return;
      } else {
        this._fulfillNameChange(columnName, index, e);
      }
    } else {
      this._fulfillNameChange(columnName, index, e);
    }
  };

  _fulfillNameChange = (columnName, index, e) => {
    this._changeService(columnName, index, e);

    let servicesTable = this.state.servicesTable;
    let service = this._fillService(servicesTable[index], e.value);

    servicesTable[index] = service;

    this.setState({
      servicesTable,
    });
  };

  _fillService = (service, serviceName) => {
    const dataByServiceName = this._getDataByServiceName(serviceName);
    service.Code = dataByServiceName.code;
    service.Code_new = dataByServiceName.codeNew;
    service.isTooth = dataByServiceName.isTooth;

    if (!service.toothNum) service.toothNum = service.isTooth ? "0" : "";

    return service;
  };

  onComment = (columnName, index, e) => {
    e.preventDefault();
    this._changeService(columnName, index, e.target);
  };

  onChangeToothNum = (columnName, index, e) => {
    e.preventDefault();

    const re = /^[-:+\d(), ]+$/;
    if (re.test(e.target.value) || e.target.value.length === 0) {
      this._changeService(columnName, index, e.target);
    }
  };

  addService = () => {
    let lastIndex = this.state.servicesTable.length - 1;
    let newId;

    lastIndex < 0
      ? (newId = 0)
      : (newId = this.state.servicesTable[lastIndex]._id + 1);

    let newService = {
      _id: newId,
      serviceName: "",
      approveResult: false,
      declineReason: "",
      toothNum: "",
      corrected: "Нет",
      serviceCount: 1,
      isTooth: false,
      initialApproved: true,
      sex: this.state.sex,
      age: this.state.age,
      lpuCode: this.state.lpuCode,
      policyNumber: this.state.policyNumber,
    };

    this.setState((prevState) => ({
      servicesTable: [...prevState.servicesTable, newService],
      addedServices: [...prevState.addedServices, newService],
    }));
  };

  onClassified = async (e) => {
    try {
      this.setState({
        isLoadingLocal: true,
        requestIsClassified: false,
        requestIsAnalized: false,
        requestIsApproved: false,
        addedDiagnosis: [],
        addedServices: [],
        removedDiagnosis: [],
        removedServices: [],
        countsBefore: [],
      });

      let diagnosis = [];
      let services = [];

      let data = {
        text: this.state.text,
        policyNumber: this.state.policyNumber,
        checkable: this.state.checkable,
        organization: this.state.organization,
        login: this.state.login,
        language: "en",
      };

      let response = await getRequestClassified(data);

      let requestData = await response.json();

      if (this.state.text.length > 0) {
        diagnosis = requestData.data.diagnosis;
        services = requestData.data.services;
      }

      requestData.data.diagnosis.forEach((diagnose) => {
        diagnose.exacerbation = false;
      });

      requestData.data.services.forEach((service, index) => {
        if (service.serviceCount === "") {
          service.serviceCount = "1";
        }
        service.declineReason = null;
        service.initialApproved = service.approveResult;
        service.sex = requestData.data.sex || this.state.sex;
        service.age = requestData.data.age || this.state.age;
        service.lpuCode = requestData.data.lpuCode || this.state.lpuCode;
        service.policyNumber =
          requestData.data.policyNumber || this.state.policyNumber;
        service = this._fillService(service, service.serviceName);
      });

      this.setState({
        diagnosisList: diagnosis,
        servicesTable: services,
        requestIsClassified: true,
        visibleTables: true,
        sessionId: requestData.sessionId,
        caseType: requestData.data.caseType
          ? requestData.data.caseType
          : "usual",
        sex: requestData.data.sex,
        age: requestData.data.age,
        policyNumber: requestData.data.policyNumber,
        lpuCode: requestData.data.lpuCode,
      });
    } catch (err) {
      const message = `Error: ${err.message} Intente obtener la solicitud más tarde.`;
      toast.error(message, {
        position: "top-center",
      });
    } finally {
      this.setState({
        isLoadingLocal: false,
      });
    }
  };

  _getDataByServiceName = (serviceName) => {
    let code = "";
    let codeNew = "";
    let toothNum = "";
    let isTooth = false;

    const options = [...this.state.servicesOptionsEn];

    options.forEach((service) => {
      if (service.value === serviceName) {
        code = service.Code;
        codeNew = service.Code_new;
        toothNum = service.toothNum.length ? "1" : "";
        isTooth = service.isTooth;
      }
    });

    return {
      code,
      codeNew,
      toothNum,
      isTooth,
    };
  };

  _getDataByDiagnosisName = (diagnosisName) => {
    let normativeAct = "";
    let normativeActLink = "";
    let normativeActFullText = "";
    let MKB_CODE = ";";

    diagnosisOptionsListEn.data.forEach((diagnose) => {
      if (diagnose.value === diagnosisName) {
        normativeAct = diagnose.normativeAct;
        normativeActLink = diagnose.normativeActLink;
        normativeActFullText = diagnose.normativeActFullText;
        MKB_CODE = diagnose.mkb_code;
      }
    });

    return {
      normativeAct,
      normativeActLink,
      normativeActFullText,
      MKB_CODE,
    };
  };

  onAnalized = async (e) => {
    const nameChecked = this.state.diagnosisList.find(
      (el) => el.diagnosisName !== ""
    );
    if (this.state.diagnosisList.length === 0 || !nameChecked) {
      const message = "¡Atención! No ha añadido ningún diagnóstico";

      toast.warn(message, {
        position: "top-center",
      });
      return;
    }

    try {
      this.setState({
        isLoadingLocal: true,
      });

      this._removeEmptyDiagnosis();
      await this._removeEmptyServices();

      this._saveCounters();

      let data = { data: { ...this.state, language: "en" } };

      let response = await getDataAnalyzed(data);

      let requestData = await response.json();

      let servicesTable = requestData.data;
      servicesTable.forEach((service) => {
        service.initialApproved = service.approveResult;
      });

      this.setState({
        requestIsAnalized: true,
        servicesTable,
      });
    } catch (err) {
      const message = `Error: ${err.message} Intente hacer el análisis más tarde.`;
      toast.error(message, {
        position: "top-center",
      });
    } finally {
      this.setState({
        isLoadingLocal: false,
      });
    }
  };

  _saveCounters = () => {
    const countsBefore = [...this.state.servicesTable].map(
      ({ _id, serviceCount }) => ({ [_id]: parseInt(serviceCount) })
    );
    this.setState({ countsBefore });
  };

  onApproveAllServices = () => {
    let servicesTable = this.state.servicesTable;

    servicesTable.forEach((service) => {
      service.approveResult = true;
      service.declineReason = null;
      service.corrected =
        service.approveResult !== service.initialApproved ? "Да" : "Нет";
    });

    this.setState({
      servicesTable,
    });
  };

  onDeclineAllServices = () => {
    let servicesTable = this.state.servicesTable;

    servicesTable.forEach((service) => {
      const initialReason = "No seleccionado ";
      service.approveResult = false;
      service.declineReason = initialReason;
      service.corrected =
        service.approveResult !== service.initialApproved ? "Да" : "Нет";
    });

    this.setState({
      servicesTable,
    });
  };

  onChooseReason = (rowNumber, e) => {
    let servicesTable = this.state.servicesTable;
    let service = servicesTable[rowNumber];
    service.declineReason = e.value;
    servicesTable[rowNumber] = service;

    this.setState({
      servicesTable,
    });
  };

  onChangeText = (e) => {
    this.setState({
      text: e.target.value,
    });
  };

  onApproveTask = async (e) => {
    try {
      const list = this.state.servicesTable;
      const reasons = list.map((el) => {
        return el.declineReason;
      });
      const hasNotChosen = reasons.find(
        (string) => string === "No seleccionado"
      );

      if (hasNotChosen) {
        toast.error(
          "¡Atención! No ha seleccionado todos los motivos de rechazo de servicios. Seleccione antes de la aprobación.",
          {
            position: "top-center",
          }
        );
        return;
      }

      let data = this.state;

      await getApprovedTask(data);

      this.setState({
        requestIsApproved: true,
        caseType: "usual",
      });
    } catch (err) {
      const message = `Error: ${err.message} Intente aprobar más tarde.`;
      toast.error(message, {
        position: "top-center",
      });
    }
  };

  checkIsTooth = (rowIndex) => {
    const servicesTable = this.state.servicesTable;
    return servicesTable[rowIndex].isTooth;
  };

  _removeEmptyDiagnosis = () => {
    let diagnosisList = this.state.diagnosisList;
    let newDiagnosisList = [];

    diagnosisList.forEach((diagnose) => {
      if (diagnose.diagnosisName.length !== 0) newDiagnosisList.push(diagnose);
    });

    diagnosisList = newDiagnosisList;
    this.setState({
      diagnosisList,
    });
  };

  _removeEmptyServices = async () => {
    let servicesTable = this.state.servicesTable;
    let newServicesTable = [];

    servicesTable.forEach((service) => {
      if (service.serviceName.length !== 0) newServicesTable.push(service);
    });

    servicesTable = newServicesTable;
    await this.setState({
      servicesTable,
    });
  };

  fillOrganization = async () => {
    const token = localStorage.getItem("token");

    let response = await getOrganizationChecked(token, {
      signal: this.controller.signal,
    });

    const data = await response.json();

    if (!data.organization) {
      localStorage.removeItem("token");
    }

    this.setState(data);
  };

  copyToClipboard = (content) => {
    const el = document.createElement("textarea");
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  saveMessageText = (text) => {
    this.setState({
      messageText: text,
    });
  };

  toggleCaseType = (e) => {
    e.preventDefault();

    if (e.target.value !== this.state.caseType) {
      this.setState({
        caseType: e.target.value,
      });
    } else {
      this.setState({
        caseType: "usual",
      });
    }
  };

  componentWillUnmount = () => this.controller.abort();

  controller = new AbortController();

  render() {
    let servicesTableBlock = null;
    let diagnosisListBlock = null;
    let mainBlock = null;
    let buttonBlock = null;

    let textBlockSize = this.state.visibleTables ? 4 : 12;
    let toDoText = "Aprobar";
    let doneText = "Aprobado";

    if (this.state.isLoading) {
      mainBlock = <LoaderWrapper />;
    } else {
      if (this.state.visibleTables) {
        servicesTableBlock = (
          <Row className={"table-container"}>
            <Container
              fluid
              className={"bg-light tables-block table__bordered"}
            >
              <BlockHeader heading="Servicios" />
              <Row className={"tables-block"}>
                <Col md="12" className={"column"}>
                  <Container className={"list"} fluid>
                    <ServicesTable
                      data={this.state.servicesTable}
                      declineReasonList={this.state.declineReasonList}
                      declineReasonListEn={this.state.declineReasonListEn}
                      onChooseReason={this.onChooseReason}
                      editable={this.state.requestIsAnalized}
                      onRemove={this.onRemoveService}
                      onApprove={this.onApproveService}
                      onChangeName={this.onChangeServiceName}
                      onChangeToothNum={this.onChangeToothNum}
                      onComment={this.onComment}
                      onAdd={this.addService}
                      onApproveAll={this.onApproveAllServices}
                      onDeclineAll={this.onDeclineAllServices}
                      servicesOptions={this.state.servicesOptions}
                      servicesOptionsEn={this.state.servicesOptionsEn}
                      requestIsAnalized={this.state.requestIsAnalized}
                      isTooth={this.checkIsTooth}
                      copyToClipboard={this.copyToClipboard}
                    />
                  </Container>
                </Col>
              </Row>
            </Container>
          </Row>
        );
        diagnosisListBlock = (
          <Col
            md="8"
            className={"column bg-light expandable-wrapper table__bordered"}
          >
            <BlockHeader heading="Diagnósticos" />
            <DiagnosisList
              data={this.state.diagnosisList}
              addDiagnose={this.addDiagnose}
              onChange={this.onChangeDiagnosis}
              onRemove={this.onRemoveDiagnosis}
              onChangeExacerbation={this.onChangeExacerbation}
              copyToClipboard={this.copyToClipboard}
            />
          </Col>
        );
      }

      let textBlock = (
        <Col md={textBlockSize} className={"column text-block"}>
          <BlockHeader
            heading="Solicitar texto"
            visibleTables={this.state.visibleTables}
          />
          <InputGroup>
            <InputGroup.Text>
              Rellene el texto con información sobre los servicios solicitados y
              diagnósticos en formato CIE-10
            </InputGroup.Text>
            <FormControl
              as="textarea"
              className={"text-box"}
              value={this.state.text}
              onChange={this.onChangeText}
            />
          </InputGroup>
        </Col>
      );

      mainBlock = (
        <Container fluid id="main-block">
          <Row className={"textarea-container"}>
            {textBlock}
            {diagnosisListBlock}
          </Row>
          <Row className={"button-block"}>
            <Row className={"default-block"}>
              <Col md={textBlockSize} className={"column"}>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip
                      id="tooltip-disabled"
                      style={
                        this.state.requestIsAnalized &&
                        !this.state.requestIsApproved
                          ? {}
                          : { display: "none" }
                      }
                    >
                      Primero debe aprobar solicitud actual
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <Button
                      className={"action-button"}
                      variant="primary"
                      disabled={
                        this.state.requestIsAnalized &&
                        !this.state.requestIsApproved
                      }
                      style={
                        this.state.requestIsAnalized &&
                        !this.state.requestIsApproved
                          ? { pointerEvents: "none" }
                          : {}
                      }
                      onClick={this.onClassified}
                    >
                      Reconocer solicitud
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
              {this.state.visibleTables && (
                <Col md={8}>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-preoperative">
                        Preparación preoperatoria
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <Button
                        className={"action-button preoperative-btn"}
                        variant="outline-success"
                        active={this.state.caseType === "preoperative"}
                        value={"preoperative"}
                        onClick={this.toggleCaseType}
                      >
                        <Bed />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </Col>
              )}
            </Row>
          </Row>
          {servicesTableBlock}
        </Container>
      );

      buttonBlock = (
        <Container fluid className={"button-block"}>
          <Row className={"default-block"}>
            <Col md="4" className={"column"}>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-analized">
                    Haciendo clic en el botón "Analizar solicitud", Usted
                    confirma que la lista de servicios y diagnósticos
                    corresponde a la solicitud de la clínica.
                  </Tooltip>
                }
              >
                <Button
                  className={"action-button"}
                  variant="primary"
                  disabled={
                    !this.state.requestIsClassified ||
                    this.state.isLoading ||
                    !this.state.servicesTable.length ||
                    this.state.requestIsApproved
                  }
                  onClick={this.onAnalized}
                >
                  Analizar solicitud
                </Button>
              </OverlayTrigger>
            </Col>
            <Col md="5" className={"column"}>
              <UsefulBlock
                data={this.state}
                saveMessageText={this.saveMessageText}
              />
            </Col>
            <Col md="3" className={"column"}>
              <OverlayTrigger
                placement="left-start"
                overlay={
                  <Tooltip
                    id="tooltip-disabled-approve"
                    className="tooltip-approve"
                    style={
                      this.state.requestIsApproved ? {} : { display: "none" }
                    }
                  >
                    Listo. Ingrese el texto de la nueva solicitud.
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <Button
                    className={"action-button"}
                    variant="success"
                    disabled={
                      !this.state.requestIsAnalized ||
                      this.state.requestIsApproved ||
                      this.state.isLoading
                    }
                    onClick={this.onApproveTask}
                  >
                    {this.state.requestIsApproved ? (
                      <>
                        <Done /> {doneText}
                      </>
                    ) : (
                      toDoText
                    )}
                  </Button>
                </span>
              </OverlayTrigger>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <>
        <Container
          id={"MainPage"}
          fluid
          className={`text-light ${
            this.state.caseType === "preoperative" && "green-bg"
          }`}
        >
          <CustomContentComponent type="main_content">
            {mainBlock}
            {this.state.visibleTables && buttonBlock}
            {this.state.isLoadingLocal && <OverLayLoader />}
          </CustomContentComponent>
        </Container>
      </>
    );
  }
}

export default MainPage;
